import React from 'react';

interface IScreenWrapperProps {
    children:React.ReactNode
}
export const ScreenWrapper = ({children}: IScreenWrapperProps) => {
  return (
    <div className="frame">
        <div className="bezel">
            <div className="crt">
              <div className="scanline"></div>
              <div className="terminal">
                {children}
              </div>
            </div>
        </div>

    </div>
  )
}
