import React from "react";

export default function Landing() {
  return (
      <div className="landing-body">
        <div className="landing-left">
          <h1>Welcome</h1>
        </div>
        <div className="landing-right">
          {/* <img className="img" alt="portrait" src="meStanding.png" width={200} /> */}
        </div>
      </div>
    
  );
}
