import React from 'react'
import { Link } from 'react-router-dom'

export const Navbar = () => {
  return (
    <nav className='navbar'>
        <ul>
            <li>
                <Link className="homeLink" to="/">
                  <span className="home">Home</span>
                </Link>
            </li>
            <li>
                <Link className="blogLink" to="/blog">
                    <span className='blog'>Blog</span>
                </Link>
            </li>
        </ul>
    </nav>
  )
}
