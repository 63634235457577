import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Width from "./components/Width";
import Landing from "./pages/Landing";
import { ScreenWrapper } from "./components/Screen/ScreenWrapper";
import { Navbar } from "./components/Navbar";
function App() {
  return (
    <Width>
      <BrowserRouter>
        <ScreenWrapper>
          <Navbar/>
          <Routes>
            <Route path="/" element={<Landing />} />
          </Routes>
        </ScreenWrapper>
      </BrowserRouter>
    </Width>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
